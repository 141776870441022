import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Sagittarius.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Sagittarius Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/sagittarius"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Sagittarius</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Sagittarius Traits</h4>
              
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Sagittarius Traits</h1>
                <h2 className="text-sm md:text-base ml-4">Nov 22 - Dec 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Sagittarius Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Sagittarius Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Sagittarius Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-love");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-nature");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-man");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-traits");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/sagittarius/sagittarius-facts");
                  }}>
                  <h5 className="font-semibold text-black">Sagittarius Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              
            <p className="playfair text-black text-xl md:text-3xl">Let's look at this zodiac sign's positive and negative personality traits.</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Positive Traits:</p> creative, Caring, Honest, and Wild
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Negative Traits:</p>  Impatient, Careless, Egocentric, Attention seeking 
<br/><br/>

<p className="playfair text-black text-xl md:text-3xl">Sagittarius Positive Traits</p><br/>

Creative
Sagittarius people are the most intelligent people, and they are very creative and talented. They work to achieve their goals, and goals are fulfilled through their creativity. They think creatively and often think outside the box.
<br/><br/>
Caring
The Sagittarius people are caring in nature. They care for everything and create and sustain a rich culture of human-nature connection. Caring too much is the one thing Sagittarius battles with all the time.
<br/><br/>
Deep
Sagittarius is a deep thinker. He was known for his deep thinking, his ability to find solutions, and his ability to think about things in a way that was interesting and useful. He was called the "Sage of Wisdom" because he was very wise and could be very helpful in many situations.<br/><br/>
Honest
Sagittarius is the personification of the spirit of the zodiac, the zodiac. Sagittarius is said to be the most honest person in all zodiac signs because it is not affected by the corruption of the material world. It is the most righteous of all the animals but is the most honest among human beings. <br/><br/>
Wild
Sagittarius is wild, independent, fun, friendly, and outgoing. Sagittarius is the sign of the warrior, fighter, and free spirit. Sagittarius is an independent and curious person that likes to be busy. He is often unafraid to take risks and do things not usually done. Sagittarius's personality is full of energy, and he does not want to waste time.<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Sagittarius Negative Traits</p><br/>
Impatient
Sagittarians never want to slow things down. They are nervous and impatient. When things are not moving as per their choices, they get frustrated and moody.
<br/><br/>
Careless
the Sagittarius people are known for their carelessness and recklessness. When there is a dispute, the Sagittarius people are known for their arrogance and stubbornness. Their negligence often attracts criticism from all corners.
<br/><br/>
Boastful
The Sagittarians are people who are often very boastful, constantly boasting about their accomplishments and achievements. They usually have a grandiose sense of self-importance, pride, and self-esteem. They are also quite friendly and outgoing and easily socialize.
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Attention Seeking:</p>
Sagittarians are also known for being irritating attention-seekers. Sagittarians might even threaten to harm themselves to get attention.
<br/><br/>
Sagittarius Love And Relationships
Sagittarius in a relationship is very attentive and generously plans extraordinary things to make their loved ones happy. Sagittarius is an adventurous and optimistic zodiac sign. People born under this sign don't like to settle down and prefer to keep their options open. They are passionate and energetic and love to be in a relationship. Sagittarius loves to be in a relationship and will do almost anything to keep their significant other happy.
<br/><br/>
They are confident and bold, and they don't mind being the center of attention. Sagittarius people are also known for being great lovers.
Sagittarius has a fun and adventurous spirit. They are always looking for the next great adventure and love to explore. They don't mind getting a little dirty as long as they have a good time. They are also great listeners and have a philosophy of "trying everything at least once."
<br/><br/>


Sagittarius Friends And Family
Sagittarius is very enthusiastic and amusing. Thus, they are never short of friends wherever they go. Sagittarius individuals are very fun-loving, and they can make even a tedious activity an interesting one. Sagittarius has an outgoing personality and has no difficulty cultivating lasting friendships. They are an exciting friend to have. Their open communication makes them easy to get along with anyone.
<br/><br/>
Aries strongly value their families, though they are rarely close to them. They are honest and straightforward towards their family even though they are pretty complex while expressing their feelings. They possess great independence and ambitions, making them self-reliant from a very young age. They never refuse to take on family obligations and are ever ready to work for them when they need to be taken care of. Despite not maintaining regular contact with the family, they always cherish familial ties.
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Open-hearted:</p> Sagittarius is dedicated and willing to do almost anything regarding family. They are remarkably open-handed and kind to their families and companions. They will always be loyal to their loved ones. Sagittarius loves life, adores laughing, and is always eager to undertake funny and crazy things. Sagittarius is responsible and family-oriented. They struggle a lot for their families.
<br/><br/>

Sagittarius is a fire sign, which means that their friends and family come from different backgrounds. Some Sagittarius people have a large family, while others are only children or grew up as only children. And just like the other fire signs, Sagittarius people have a lot of friends. They are very social and like to have a lot of people around them.
<br/><br/>
Sagittarius is the fire sign of the zodiac. Because of this, Sagittarians are known for their strong personalities, infectious laughs, and optimistic outlook on life. Sagittarians are also known for their sense of humor and are frequently the party's life. This sense of humor often extends to Sagittarians' relationships with their friends and family.
<br/><br/>
Sagittarians are cheerful, open-minded extroverts with adventurous spirits. They love exploring and discovering new things, and they're always ready for a good time. Sagittarians also tend to be passionate and direct about their feelings, making great friends and confidants. 
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Sagittarius Career And Money</p><br/>
Sagittarius and Career
Work-life balance is their greatest career strength. Sagittarians love their job, so they work very hard and push themselves to the max. They like having flexibility, ensuring that they get their job done well. Thus, Sagittarius needs a career that provides them with plenty of space and excitement. But for Sagittarius, it is hard to focus on one thing as they have many interests and love exploring new things.
<br/><br/>
Sagittarius and Money
Making money is one of the most essential things in your life as a Sagittarius. You have a unique ability to turn your ideas into reality, and you're more than capable of pursuing your goals with single-minded determination. Your enthusiasm and optimism are your greatest assets, and you're always looking for ways to improve your skills and further your career. Life is an adventure for you, so you want to make the most of it while you're young.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">How To Attract Sagittarius</p><br/>
Sagittarius are some of the most exciting, adventurous, and charming people. To attract a free-spirited archer, you need to put your game face on. Follow the below steps to win the hearts of Sagittarius.
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Be fun and playful:</p> Sagittarius is a fun-loving sign, and as such, they want to surround themselves with like-minded people and experiences. Avoid being negative around them, and try to be as positive as possible. Sagittarius is a cheerful and optimistic sign, and being around negative people can dampen their spirits.
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Do not act c lingy:</p>
Sagittarius loves freedom. They like to be with people who won't clutter their liberty. They like to be with people who will leave them alone when they want to be alone. They like to be with people who will help them find their way when they need help.
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Be ready to travel:</p>
To attract Sagittarius, you should have a love for travel. Ask them about their travel experiences as they love adventure and exploring to start talking with them. They would be more interested in your talks if you suggest things they can do that also involve traveling.
<br/><br/>
Compatible Signs Sagittarius Should Consider
Sagittarius's sign is gregarious, fun-loving, and dramatically values freedom and liberty. The most compatible signs with Sagittarius are generally considered to be Aries, Aquarius, and Leo.
<br/><br/>

            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
